<template>
  <div class="kl-footer">
    <div class="kl-footer-store">
      <div class="kl-footer-store-info">
        <p>
          <img src="@/assets/imgs/base/icon-store.png"
               alt="Store Icon" />
          <span class="font-HarmonyOS_Sans_SC_Light"
                @click="navigateTo">线下门店</span>
        </p>
        <!-- <img class="kl-img-qrcode hiden"
             src=""
             alt="QR Code" />
        <span class="font-HarmonyOS_Sans_SC_Light hiden">欢迎您<br> 扫码观看美课</span> -->
      </div>
      <div class="kl-footer-social">
        <p class="font-HarmonyOS_Sans_SC_Medium">关注我们</p>
        <img src="@/assets/imgs/base/icon-wx2.png"
             alt="WeChat Icon" />
        <img src="@/assets/imgs/base/icon-wb.png"
             alt="Weibo Icon" />
        <img src="@/assets/imgs/base/icon-rbook.png"
             alt="Red Book Icon" />
        <img src="@/assets/imgs/base/icon-tiktok.png"
             alt="TikTok Icon" />
      </div>
    </div>

    <div class="kl-footer-info">
      <div class="kl-footer-links">
        <div>
          <p class="font-HarmonyOS_Sans_SC_Medium">官网福利</p>
          <span class="font-HarmonyOS_Sans_SC_Light">正品保证<br>贴心售后</span>
        </div>
        <div class="hiden">
          <p class="font-HarmonyOS_Sans_SC_Medium">使用规范</p>
          <span class="font-HarmonyOS_Sans_SC_Light">使用政策 隐私条款<br>Cookies政策</span>
        </div>
        <div>
          <p class="font-HarmonyOS_Sans_SC_Medium">联系我们</p>
          <span class="font-HarmonyOS_Sans_SC_Light">珂蓝5号客服热线<br>023-86999380</span>
        </div>
      </div>
      <p class="kl-footer-copyright font-HarmonyOS_Sans_SC_Light">
        <span>珂蓝五号化妆品有限公司</span>
        <span>版权所有 地址:重庆市巴南区木洞镇瑞翊路333号</span>
      </p>
    </div>
    <div class="record"
         @click="toRecode">备案号：渝ICP备2024046041号-1</div>
  </div>
</template>
<script setup>
import { useRouter, useRoute } from 'vue-router'
const router = useRouter()
function navigateTo() {
  router.push({ path: '/join', query: { to: 'store' } })
}
function toRecode() { 
  window.open("https://beian.miit.gov.cn", "_blank");
}
</script>
<style lang="scss" scoped>
@import './pc.scss';
@import './ipad.scss';
@import './mobile.scss';
.hiden {
  // opacity: 0;
  display: none;
}

.record {
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translate(-50%, 50%);
  cursor: pointer;
}
</style>
